import { Button, Flex, Grid, Input, styled, Table } from '@conteg/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { PageLayout, PageLayoutContent } from 'components/basic-layout';
import { TimeSelect } from 'components/time-select/time-select';
import { UNLIMITED_PAGE_SIZE } from 'config';
import {
  FilterTypeSelect,
  FiltrType,
} from 'pages/events/content-tracking/filter-type-select';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  ContentTrackingQueryVariables,
  useContentTrackingQuery,
} from 'types/generated/graphql';
import { formatDateTime, formatDateTimeToIso } from 'utils/format/format';
import { notEmpty } from 'utils/not-empty/not-empty';

import { AdditionalInfo, TableItem } from './additional-info';

const tableColumnHelper = createColumnHelper<TableItem>();

const EventsTable = styled.div`
  white-space: nowrap;
`;

export const ContentTracking: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultTrackingIdentifier = searchParams.get('trackingIdentifier');
  const defaultPhoneNumber = searchParams.get('phoneNumber');

  const [variables, setVariables] = useState<ContentTrackingQueryVariables>({
    pageIndex: 0,
    pageSize: UNLIMITED_PAGE_SIZE,
    trackingIdentifier: defaultTrackingIdentifier,
    phoneNumber: defaultPhoneNumber,
    from: searchParams.get('from'),
    to: searchParams.get('to'),
  });

  const [trackingIdentifier, setTrackingIdentifier] = useState<string | null>(
    defaultTrackingIdentifier
  );
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    defaultPhoneNumber
  );

  const isQueryEnabled =
    !!variables.trackingIdentifier || !!variables.phoneNumber;

  const {
    data: trackingData,
    isLoading,
    isRefetching,
    error,
    refetch,
  } = useContentTrackingQuery(
    {
      ...variables,
      from: formatDateTimeToIso(variables.from),
      to: formatDateTimeToIso(variables.to),
    },
    {
      enabled: isQueryEnabled,
      select: (data) => data.contentTracking?.filter(notEmpty) || [],
    }
  );

  useEffect(() => {
    const { pageIndex, pageSize, ...rest } = variables;
    const searchParams: Record<string, string> = {};

    Object.entries(rest)
      .filter((entry) => entry[1])
      .forEach(([key, value]) => {
        if (value) {
          searchParams[key] = value;
        }
      });

    setSearchParams(searchParams);
  }, [
    searchParams,
    setSearchParams,
    variables,
    variables.trackingIdentifier,
    variables.phoneNumber,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateFilter({ trackingIdentifier, phoneNumber });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [trackingIdentifier, phoneNumber]);

  const tableColumns = useMemo(
    () => [
      tableColumnHelper.accessor((row) => row.timeStampUtc, {
        id: 'ce_type',
        cell: (info) => formatDateTime(info.getValue()),
        header: () => <span>{t('Table.Date')}</span>,
        enableSorting: false,
      }),
      tableColumnHelper.accessor((row) => row?.contentEventType, {
        id: 'ce_time',
        cell: (info) => t(info.getValue()),
        header: () => <span>{t('Table.Type')}</span>,
        enableSorting: false,
      }),
      tableColumnHelper.accessor((row) => row, {
        id: 'additional_info',
        cell: (info) => <AdditionalInfo event={info.getValue()} />,
        header: () => <span></span>,
        enableSorting: false,
      }),
    ],
    [t]
  );

  const eventsTable = useReactTable({
    data: trackingData || [],
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  });

  const updateFilter = (values: Partial<ContentTrackingQueryVariables>) => {
    setVariables((current) => ({ ...current, ...values }));
  };

  return (
    <PageLayout title={t('Events.PageTitle')}>
      <PageLayoutContent>
        <FilterTypeSelect active={FiltrType.ContentTracking} />
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom="5rem"
        >
          <Grid
            grid
            gridTemplateColumns="35rem 40rem auto"
            gridColumnGap="10rem"
            alignItems="center"
          >
            <Input
              value={trackingIdentifier || ''}
              placeholder={t('Filter.TrackingIdentifier')}
              label={t('Filter.TrackingIdentifier')}
              onChange={(ev) => setTrackingIdentifier(ev.target.value || null)}
            />
            <Input
              value={phoneNumber || ''}
              placeholder={t('Filter.PhoneNumber')}
              label={t('Filter.PhoneNumber')}
              onChange={(ev) => setPhoneNumber(ev.target.value || null)}
            />
            <Flex gap="3rem" alignItems="center">
              <TimeSelect
                onChange={(from) => from && updateFilter({ from })}
                value={variables.from || ''}
                defaultTime="00:00"
              />
              -
              <TimeSelect
                onChange={(to) => to && updateFilter({ to })}
                value={variables.to || ''}
                defaultTime="23:59"
              />
            </Flex>
          </Grid>
          <Flex alignItems="center" gap="1rem">
            <Button
              disabled={!isQueryEnabled}
              title={t('Filter.Refresh')}
              onClick={refetch}
            />
            <Button
              variant="danger"
              title={t('Filter.CancelFilter')}
              onClick={() => {
                setVariables((current) => ({
                  pageIndex: current.pageIndex,
                  pageSize: current.pageSize,
                  trackingIdentifier: null,
                  phoneNumber: null,
                }));
                setTrackingIdentifier(null);
                setPhoneNumber(null);
              }}
            />
          </Flex>
        </Flex>
        <EventsTable>
          <Table
            table={eventsTable}
            isEmpty={!trackingData?.length}
            isLoading={(isLoading || isRefetching) && isQueryEnabled}
            emptyState={{
              title: t('Table.EmptyState.Title'),
              description: isQueryEnabled
                ? t('Table.EmptyState.NoDataForFilter')
                : t('Table.EmptyState.FillFilter'),
            }}
            errorInfo={{
              error,
              extendedDescription: {
                title: t('Table.Error.ExtendedDescription'),
              },
              shortDescription: {
                title: t('Table.Error.Title'),
                description: t('Table.Error.Description'),
              },
            }}
          />
        </EventsTable>
      </PageLayoutContent>
    </PageLayout>
  );
};
