import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetcher } from '../../utils/gql/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: string; output: string };
  UUID: { input: string; output: string };
};

export type AllocationInspected = {
  __typename?: 'AllocationInspected';
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type AllocationTerminated = {
  __typename?: 'AllocationTerminated';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export type ClientPhoneNumber = {
  __typename?: 'ClientPhoneNumber';
  clientType: ClientType;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export enum ClientType {
  Depositor = 'DEPOSITOR',
  Recipient = 'RECIPIENT',
  Sender = 'SENDER',
}

export type ContentDeallocated = {
  __typename?: 'ContentDeallocated';
  contentEventType: ContentEventTypeEnum;
  storageUnitId: Scalars['UUID']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
};

export enum ContentEventTypeEnum {
  Deallocated = 'DEALLOCATED',
  Inspected = 'INSPECTED',
  PickedUp = 'PICKED_UP',
  StockedIn = 'STOCKED_IN',
  StockedOut = 'STOCKED_OUT',
  Stored = 'STORED',
  SubmissionCodeValidated = 'SUBMISSION_CODE_VALIDATED',
  Terminated = 'TERMINATED',
  UnknownContentFound = 'UNKNOWN_CONTENT_FOUND',
  VerifyAccessCredentialsSuccess = 'VERIFY_ACCESS_CREDENTIALS_SUCCESS',
}

export type ContentPickedUp = {
  __typename?: 'ContentPickedUp';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type ContentStockedIn = {
  __typename?: 'ContentStockedIn';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  courierIdentifier?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type ContentStockedOut = {
  __typename?: 'ContentStockedOut';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  courierIdentifier?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type ContentStored = {
  __typename?: 'ContentStored';
  clientPhoneNumbers?: Maybe<Array<ClientPhoneNumber>>;
  contentEventType: ContentEventTypeEnum;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pinCode?: Maybe<Scalars['String']['output']>;
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type ContentTracking =
  | AllocationInspected
  | AllocationTerminated
  | ContentDeallocated
  | ContentPickedUp
  | ContentStockedIn
  | ContentStockedOut
  | ContentStored
  | SubmissionCodeValidated
  | UnknownContentFound
  | VerifyPickUpAccessCredentialsSuccess;

export type ExportSmsDto = {
  __typename?: 'ExportSmsDto';
  messagesCount: Scalars['Int']['output'];
  pointId?: Maybe<Scalars['UUID']['output']>;
  pointRegistrationNumber?: Maybe<Scalars['String']['output']>;
  projectId?: Maybe<Scalars['UUID']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  smsMessagesCount: Scalars['Int']['output'];
  tenantSubjectId?: Maybe<Scalars['UUID']['output']>;
  tenantSubjectName?: Maybe<Scalars['String']['output']>;
};

export enum LockStatus {
  Closed = 'CLOSED',
  Failed = 'FAILED',
  Opened = 'OPENED',
}

export type PointMessageDto = {
  __typename?: 'PointMessageDto';
  deactivationReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  installationId: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  isConfigured: Scalars['Boolean']['output'];
  isManualLayoutManagement: Scalars['Boolean']['output'];
  isOpenForEdit: Scalars['Boolean']['output'];
  pointIdentifier?: Maybe<Scalars['String']['output']>;
  pointPrefix?: Maybe<Scalars['String']['output']>;
  pointRegistrationNumber?: Maybe<Scalars['String']['output']>;
  pointSequenceNumber: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  contentTracking?: Maybe<Array<Maybe<ContentTracking>>>;
  points?: Maybe<Array<Maybe<PointMessageDto>>>;
  sentSmsLogs: Array<ExportSmsDto>;
  storageUnitTracking?: Maybe<StorageUnitStatusChange>;
};

export type QueryContentTrackingArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  pageIndex?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  trackingIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySentSmsLogsArgs = {
  pointId?: InputMaybe<Scalars['UUID']['input']>;
  projectId?: InputMaybe<Scalars['UUID']['input']>;
  smsSentAtFrom?: InputMaybe<Scalars['String']['input']>;
  smsSentAtTo?: InputMaybe<Scalars['String']['input']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryStorageUnitTrackingArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  pointId?: InputMaybe<Scalars['UUID']['input']>;
  storageUnitName?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StorageUnitStatusChange = {
  __typename?: 'StorageUnitStatusChange';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  items: Array<StorageUnitTrackingMessageDto>;
  pageIndex: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type StorageUnitTrackingMessageDto = {
  __typename?: 'StorageUnitTrackingMessageDto';
  installationId: Scalars['UUID']['output'];
  lockStatus: LockStatus;
  pointId: Scalars['UUID']['output'];
  pointRegistrationNumber: Scalars['String']['output'];
  storageUnitId?: Maybe<Scalars['UUID']['output']>;
  storageUnitName: Scalars['String']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
};

export type SubmissionCodeValidated = {
  __typename?: 'SubmissionCodeValidated';
  contentEventType: ContentEventTypeEnum;
  isScanned: Scalars['Boolean']['output'];
  isSuccessful: Scalars['Boolean']['output'];
  timeStampUtc: Scalars['DateTime']['output'];
};

export type UnknownContentFound = {
  __typename?: 'UnknownContentFound';
  actualStorageUnitId: Scalars['UUID']['output'];
  contentEventType: ContentEventTypeEnum;
  expectedStorageUnitId?: Maybe<Scalars['UUID']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type VerifyPickUpAccessCredentialsSuccess = {
  __typename?: 'VerifyPickUpAccessCredentialsSuccess';
  contentEventType: ContentEventTypeEnum;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pinCode?: Maybe<Scalars['String']['output']>;
  timeStampUtc: Scalars['DateTime']['output'];
};

export type AllocationInspectedFragment = {
  __typename?: 'AllocationInspected';
  firstName?: string | null;
  lastname?: string | null;
  contentEventType: ContentEventTypeEnum;
  timeStampUtc: string;
  email?: string | null;
};

export type AllocationTerminatedFragment = {
  __typename?: 'AllocationTerminated';
  firstName?: string | null;
  lastname?: string | null;
  contentEventType: ContentEventTypeEnum;
  timeStampUtc: string;
  email?: string | null;
};

export type ContentDeallocatedFragment = {
  __typename?: 'ContentDeallocated';
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type ContentPickedUpFragment = {
  __typename?: 'ContentPickedUp';
  timeStampUtc: string;
  email?: string | null;
  firstName?: string | null;
  lastname?: string | null;
  contentEventType: ContentEventTypeEnum;
};

export type ContentStockedInFragment = {
  __typename?: 'ContentStockedIn';
  courierIdentifier?: string | null;
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type ContentStockedOutFragment = {
  __typename?: 'ContentStockedOut';
  courierIdentifier?: string | null;
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
  email?: string | null;
  firstName?: string | null;
  lastname?: string | null;
};

export type ContentStoredFragment = {
  __typename?: 'ContentStored';
  pinCode?: string | null;
  phoneNumber?: string | null;
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type ExportSmsFragment = {
  __typename?: 'ExportSmsDto';
  pointRegistrationNumber?: string | null;
  tenantSubjectName?: string | null;
  projectName?: string | null;
  smsMessagesCount: number;
  messagesCount: number;
};

export type SubmissionCodeValidatedFragment = {
  __typename?: 'SubmissionCodeValidated';
  isSuccessful: boolean;
  isScanned: boolean;
  contentEventType: ContentEventTypeEnum;
  timeStampUtc: string;
};

export type UnknownContentFoundFragment = {
  __typename?: 'UnknownContentFound';
  timeStampUtc: string;
  contentEventType: ContentEventTypeEnum;
};

export type VerifyPickUpAccessCredentialsSuccessFragment = {
  __typename?: 'VerifyPickUpAccessCredentialsSuccess';
  pinCode?: string | null;
  phoneNumber?: string | null;
  timeStampUtc: string;
  email?: string | null;
  firstName?: string | null;
  lastname?: string | null;
  contentEventType: ContentEventTypeEnum;
};

export type ContentTrackingQueryVariables = Exact<{
  trackingIdentifier?: InputMaybe<Scalars['String']['input']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  pageIndex?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type ContentTrackingQuery = {
  __typename?: 'Query';
  contentTracking?: Array<
    | {
        __typename: 'AllocationInspected';
        firstName?: string | null;
        lastname?: string | null;
        contentEventType: ContentEventTypeEnum;
        timeStampUtc: string;
        email?: string | null;
      }
    | {
        __typename: 'AllocationTerminated';
        firstName?: string | null;
        lastname?: string | null;
        contentEventType: ContentEventTypeEnum;
        timeStampUtc: string;
        email?: string | null;
      }
    | {
        __typename: 'ContentDeallocated';
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'ContentPickedUp';
        timeStampUtc: string;
        email?: string | null;
        firstName?: string | null;
        lastname?: string | null;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'ContentStockedIn';
        courierIdentifier?: string | null;
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'ContentStockedOut';
        courierIdentifier?: string | null;
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
        email?: string | null;
        firstName?: string | null;
        lastname?: string | null;
      }
    | {
        __typename: 'ContentStored';
        pinCode?: string | null;
        phoneNumber?: string | null;
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'SubmissionCodeValidated';
        isSuccessful: boolean;
        isScanned: boolean;
        contentEventType: ContentEventTypeEnum;
        timeStampUtc: string;
      }
    | {
        __typename: 'UnknownContentFound';
        timeStampUtc: string;
        contentEventType: ContentEventTypeEnum;
      }
    | {
        __typename: 'VerifyPickUpAccessCredentialsSuccess';
        pinCode?: string | null;
        phoneNumber?: string | null;
        timeStampUtc: string;
        email?: string | null;
        firstName?: string | null;
        lastname?: string | null;
        contentEventType: ContentEventTypeEnum;
      }
    | null
  > | null;
};

export type PointsQueryVariables = Exact<{ [key: string]: never }>;

export type PointsQuery = {
  __typename?: 'Query';
  points?: Array<{
    __typename?: 'PointMessageDto';
    id: string;
    pointSequenceNumber: number;
    pointRegistrationNumber?: string | null;
  } | null> | null;
};

export type SentSmsLogsQueryVariables = Exact<{
  smsSentAtFrom?: InputMaybe<Scalars['String']['input']>;
  smsSentAtTo?: InputMaybe<Scalars['String']['input']>;
}>;

export type SentSmsLogsQuery = {
  __typename?: 'Query';
  sentSmsLogs: Array<{
    __typename?: 'ExportSmsDto';
    pointRegistrationNumber?: string | null;
    tenantSubjectName?: string | null;
    projectName?: string | null;
    smsMessagesCount: number;
    messagesCount: number;
  }>;
};

export type StorageUnitTrackingMessageFragment = {
  __typename?: 'StorageUnitTrackingMessageDto';
  storageUnitName: string;
  pointRegistrationNumber: string;
  pointId: string;
  installationId: string;
  lockStatus: LockStatus;
  timeStampUtc: string;
};

export type StorageUnitTrackingQueryVariables = Exact<{
  pointId?: InputMaybe<Scalars['UUID']['input']>;
  storageUnitName?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
}>;

export type StorageUnitTrackingQuery = {
  __typename?: 'Query';
  storageUnitTracking?: {
    __typename?: 'StorageUnitStatusChange';
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    totalCount: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    items: Array<{
      __typename?: 'StorageUnitTrackingMessageDto';
      storageUnitName: string;
      pointRegistrationNumber: string;
      pointId: string;
      installationId: string;
      lockStatus: LockStatus;
      timeStampUtc: string;
    }>;
  } | null;
};

export const AllocationInspectedFragmentDoc = `
    fragment AllocationInspected on AllocationInspected {
  firstName
  lastname
  contentEventType
  timeStampUtc
  email
}
    `;
export const AllocationTerminatedFragmentDoc = `
    fragment AllocationTerminated on AllocationTerminated {
  firstName
  lastname
  contentEventType
  timeStampUtc
  email
}
    `;
export const ContentDeallocatedFragmentDoc = `
    fragment ContentDeallocated on ContentDeallocated {
  timeStampUtc
  contentEventType
}
    `;
export const ContentPickedUpFragmentDoc = `
    fragment ContentPickedUp on ContentPickedUp {
  timeStampUtc
  email
  firstName
  lastname
  contentEventType
}
    `;
export const ContentStockedInFragmentDoc = `
    fragment ContentStockedIn on ContentStockedIn {
  courierIdentifier
  timeStampUtc
  contentEventType
}
    `;
export const ContentStockedOutFragmentDoc = `
    fragment ContentStockedOut on ContentStockedOut {
  courierIdentifier
  timeStampUtc
  contentEventType
  email
  firstName
  lastname
}
    `;
export const ContentStoredFragmentDoc = `
    fragment ContentStored on ContentStored {
  pinCode
  phoneNumber
  timeStampUtc
  contentEventType
}
    `;
export const ExportSmsFragmentDoc = `
    fragment ExportSms on ExportSmsDto {
  pointRegistrationNumber
  tenantSubjectName
  projectName
  smsMessagesCount
  messagesCount
}
    `;
export const SubmissionCodeValidatedFragmentDoc = `
    fragment SubmissionCodeValidated on SubmissionCodeValidated {
  isSuccessful
  isScanned
  contentEventType
  timeStampUtc
}
    `;
export const UnknownContentFoundFragmentDoc = `
    fragment UnknownContentFound on UnknownContentFound {
  timeStampUtc
  contentEventType
}
    `;
export const VerifyPickUpAccessCredentialsSuccessFragmentDoc = `
    fragment VerifyPickUpAccessCredentialsSuccess on VerifyPickUpAccessCredentialsSuccess {
  pinCode
  phoneNumber
  timeStampUtc
  email
  firstName
  lastname
  contentEventType
}
    `;
export const StorageUnitTrackingMessageFragmentDoc = `
    fragment StorageUnitTrackingMessage on StorageUnitTrackingMessageDto {
  storageUnitName
  pointRegistrationNumber
  pointId
  installationId
  lockStatus
  timeStampUtc
}
    `;
export const ContentTrackingDocument = `
    query ContentTracking($trackingIdentifier: String, $tenantSubjectId: UUID, $from: DateTime, $to: DateTime, $pageSize: Int, $pageIndex: Int, $phoneNumber: String) {
  contentTracking(
    trackingIdentifier: $trackingIdentifier
    tenantSubjectId: $tenantSubjectId
    from: $from
    to: $to
    pageSize: $pageSize
    pageIndex: $pageIndex
    phoneNumber: $phoneNumber
  ) {
    __typename
    ... on ContentStockedIn {
      ...ContentStockedIn
    }
    ... on ContentStockedOut {
      ...ContentStockedOut
    }
    ... on ContentStored {
      ...ContentStored
    }
    ... on ContentPickedUp {
      ...ContentPickedUp
    }
    ... on ContentDeallocated {
      ...ContentDeallocated
    }
    ... on UnknownContentFound {
      ...UnknownContentFound
    }
    ... on AllocationInspected {
      ...AllocationInspected
    }
    ... on AllocationTerminated {
      ...AllocationTerminated
    }
    ... on SubmissionCodeValidated {
      ...SubmissionCodeValidated
    }
    ... on VerifyPickUpAccessCredentialsSuccess {
      ...VerifyPickUpAccessCredentialsSuccess
    }
  }
}
    ${ContentStockedInFragmentDoc}
${ContentStockedOutFragmentDoc}
${ContentStoredFragmentDoc}
${ContentPickedUpFragmentDoc}
${ContentDeallocatedFragmentDoc}
${UnknownContentFoundFragmentDoc}
${AllocationInspectedFragmentDoc}
${AllocationTerminatedFragmentDoc}
${SubmissionCodeValidatedFragmentDoc}
${VerifyPickUpAccessCredentialsSuccessFragmentDoc}`;

export const useContentTrackingQuery = <
  TData = ContentTrackingQuery,
  TError = unknown,
>(
  variables?: ContentTrackingQueryVariables,
  options?: Omit<
    UseQueryOptions<ContentTrackingQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<ContentTrackingQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<ContentTrackingQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['ContentTracking']
        : ['ContentTracking', variables],
    queryFn: fetcher<ContentTrackingQuery, ContentTrackingQueryVariables>(
      ContentTrackingDocument,
      variables
    ),
    ...options,
  });
};

export const PointsDocument = `
    query Points {
  points {
    id
    pointSequenceNumber
    pointRegistrationNumber
  }
}
    `;

export const usePointsQuery = <TData = PointsQuery, TError = unknown>(
  variables?: PointsQueryVariables,
  options?: Omit<UseQueryOptions<PointsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<PointsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<PointsQuery, TError, TData>({
    queryKey: variables === undefined ? ['Points'] : ['Points', variables],
    queryFn: fetcher<PointsQuery, PointsQueryVariables>(
      PointsDocument,
      variables
    ),
    ...options,
  });
};

export const SentSmsLogsDocument = `
    query SentSmsLogs($smsSentAtFrom: String, $smsSentAtTo: String) {
  sentSmsLogs(smsSentAtFrom: $smsSentAtFrom, smsSentAtTo: $smsSentAtTo) {
    ...ExportSms
  }
}
    ${ExportSmsFragmentDoc}`;

export const useSentSmsLogsQuery = <TData = SentSmsLogsQuery, TError = unknown>(
  variables?: SentSmsLogsQueryVariables,
  options?: Omit<
    UseQueryOptions<SentSmsLogsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SentSmsLogsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SentSmsLogsQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['SentSmsLogs'] : ['SentSmsLogs', variables],
    queryFn: fetcher<SentSmsLogsQuery, SentSmsLogsQueryVariables>(
      SentSmsLogsDocument,
      variables
    ),
    ...options,
  });
};

export const StorageUnitTrackingDocument = `
    query StorageUnitTracking($pointId: UUID, $storageUnitName: String, $from: DateTime, $to: DateTime, $pageSize: Int!, $pageIndex: Int!) {
  storageUnitTracking(
    pointId: $pointId
    storageUnitName: $storageUnitName
    from: $from
    to: $to
    pageSize: $pageSize
    pageIndex: $pageIndex
  ) {
    items {
      ...StorageUnitTrackingMessage
    }
    pageIndex
    pageSize
    totalPages
    totalCount
    hasPreviousPage
    hasNextPage
  }
}
    ${StorageUnitTrackingMessageFragmentDoc}`;

export const useStorageUnitTrackingQuery = <
  TData = StorageUnitTrackingQuery,
  TError = unknown,
>(
  variables: StorageUnitTrackingQueryVariables,
  options?: Omit<
    UseQueryOptions<StorageUnitTrackingQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      StorageUnitTrackingQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<StorageUnitTrackingQuery, TError, TData>({
    queryKey: ['StorageUnitTracking', variables],
    queryFn: fetcher<
      StorageUnitTrackingQuery,
      StorageUnitTrackingQueryVariables
    >(StorageUnitTrackingDocument, variables),
    ...options,
  });
};
