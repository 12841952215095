import { styled } from '@conteg/ui';
import { InfoText } from 'pages/events/info-text';
import { useTranslation } from 'react-i18next';
import {
  AllocationInspectedFragment,
  AllocationTerminatedFragment,
  ContentDeallocatedFragment,
  ContentPickedUpFragment,
  ContentStockedInFragment,
  ContentStockedOutFragment,
  ContentStoredFragment,
  SubmissionCodeValidated,
  UnknownContentFoundFragment,
  VerifyPickUpAccessCredentialsSuccessFragment,
} from 'types/generated/graphql';
import { booleanToYesNo } from 'utils/boolean-to-yes-no/boolean-to-yes-no';

export type TableItem =
  | ContentStockedInFragment
  | ContentStockedOutFragment
  | ContentPickedUpFragment
  | ContentDeallocatedFragment
  | VerifyPickUpAccessCredentialsSuccessFragment
  | ContentStoredFragment
  | AllocationInspectedFragment
  | AllocationTerminatedFragment
  | SubmissionCodeValidated
  | UnknownContentFoundFragment;

type Props = {
  event: TableItem;
};

export const AdditionalInfo = ({ event }: Props) => {
  const { t } = useTranslation();

  if (event.__typename === 'ContentStored') {
    return (
      <Container>
        <InfoText label={t('PhoneNumber')} value={event.phoneNumber} />
        <InfoText label={t('Pin')} value={event.pinCode} />
      </Container>
    );
  }

  if (event.__typename === 'VerifyPickUpAccessCredentialsSuccess') {
    return (
      <Container>
        <InfoText label={t('PhoneNumber')} value={event.phoneNumber} />
        <InfoText label={t('Pin')} value={event.pinCode} />
        <InfoText label={t('Table.FirstName')} value={event.firstName} />
        <InfoText label={t('Table.LastName')} value={event.lastname} />
        <InfoText label={t('Table.Email')} value={event.email} />
      </Container>
    );
  }

  if (event.__typename === 'ContentStockedIn') {
    return (
      <Container>
        <InfoText
          label={t('Table.CourierIdentifier')}
          value={event.courierIdentifier}
        />
      </Container>
    );
  }

  if (
    event.__typename === 'AllocationInspected' ||
    event.__typename === 'AllocationTerminated' ||
    event.__typename === 'ContentStockedOut' ||
    event.__typename === 'ContentPickedUp'
  ) {
    return (
      <Container>
        <InfoText label={t('Table.FirstName')} value={event.firstName} />
        <InfoText label={t('Table.LastName')} value={event.lastname} />
        <InfoText label={t('Table.Email')} value={event.email} />
        {event.__typename === 'ContentPickedUp' && (
          <InfoText
            label={t('Table.EventSource')}
            value={event.email ? 'Maintenance web' : 'Workflow web'}
          />
        )}
        {event.__typename === 'ContentStockedOut' && (
          <InfoText
            label={t('Table.CourierIdentifier')}
            value={event.courierIdentifier}
          />
        )}
      </Container>
    );
  }

  if (event.__typename === 'SubmissionCodeValidated') {
    return (
      <Container>
        <InfoText
          label={t('Table.IsScanned')}
          value={t(booleanToYesNo(event.isScanned))}
        />
        <InfoText
          label={t('Table.IsSuccessful')}
          value={t(booleanToYesNo(event.isSuccessful))}
        />
      </Container>
    );
  }

  return null;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3rem;
`;
